var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: {
            title: "详情",
            "is-mask": "",
            "display-btn": "",
            visible: _vm.visible,
            size: "small",
            "show-cancel": false,
            "show-confirm": false,
            "z-index": 3,
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  attrs: {
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c("a-form-model-item", { attrs: { label: "姓名" } }),
                  _c("div", { staticClass: "transfer_content" }, [
                    _vm._v(
                      " " + _vm._s(_vm.transferDetailData.staffName) + " "
                    ),
                  ]),
                  _c("a-form-model-item", { attrs: { label: "调动类型" } }),
                  _c("div", { staticClass: "transfer_content" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.transferDetailData.transferTypeName) +
                        " "
                    ),
                  ]),
                  _c("a-form-model-item", { attrs: { label: "生效日期" } }),
                  _c("div", { staticClass: "transfer_content" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.transferDetailData.applyTransferDate) +
                        " "
                    ),
                  ]),
                  _vm.transferDetailData.afterOrgName !==
                  _vm.transferDetailData.beforeOrgName
                    ? _c(
                        "div",
                        [
                          _c("a-form-model-item", {
                            staticClass: "line-left",
                            attrs: {
                              label: "部门",
                              "label-col": _vm.labelColC,
                              "wrapper-col": _vm.wrapperColC,
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "transfer_content transfer_border" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  attrs: {
                                    title: _vm.transferDetailData.beforeOrgName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.transferDetailData.beforeOrgName)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "middle_span" }, [
                                _vm._v("变更为"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div after_content_div",
                                  attrs: {
                                    title: _vm.transferDetailData.afterOrgName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.transferDetailData.afterOrgName)
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.transferDetailData.beforePostName !==
                  _vm.transferDetailData.afterPostName
                    ? _c(
                        "div",
                        [
                          _c("a-form-model-item", {
                            staticClass: "line-left",
                            attrs: {
                              label: "岗位",
                              "label-col": _vm.labelColC,
                              "wrapper-col": _vm.wrapperColC,
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "transfer_content transfer_border" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  attrs: {
                                    title:
                                      _vm.transferDetailData.beforePostName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transferDetailData.beforePostName
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "middle_span" }, [
                                _vm._v("变更为"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div after_content_div",
                                  attrs: {
                                    title: _vm.transferDetailData.afterPostName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.transferDetailData.afterPostName)
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.transferDetailData.beforeJobGradeName !==
                  _vm.transferDetailData.afterJobGradeName
                    ? _c(
                        "div",
                        [
                          _c("a-form-model-item", {
                            staticClass: "line-left",
                            attrs: {
                              label: "职级",
                              "label-col": _vm.labelColC,
                              "wrapper-col": _vm.wrapperColC,
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "transfer_content transfer_border" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  attrs: {
                                    title:
                                      _vm.transferDetailData.beforeJobGradeName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transferDetailData.beforeJobGradeName
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "middle_span" }, [
                                _vm._v("变更为"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div after_content_div",
                                  attrs: {
                                    title:
                                      _vm.transferDetailData.afterJobGradeName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transferDetailData.afterJobGradeName
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.transferDetailData.beforeWorkplaceName !==
                  _vm.transferDetailData.afterWorkplaceName
                    ? _c(
                        "div",
                        [
                          _c("a-form-model-item", {
                            staticClass: "line-left",
                            attrs: {
                              label: "工作地点",
                              "label-col": _vm.labelColC,
                              "wrapper-col": _vm.wrapperColC,
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "transfer_content transfer_border" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  attrs: {
                                    title:
                                      _vm.transferDetailData
                                        .beforeWorkplaceName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transferDetailData.beforeWorkplaceName
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "middle_span" }, [
                                _vm._v("变更为"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div after_content_div",
                                  attrs: {
                                    title:
                                      _vm.transferDetailData.afterWorkplaceName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transferDetailData.afterWorkplaceName
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.transferDetailData.beforeContractCompanyName !==
                  _vm.transferDetailData.afterContractCompanyName
                    ? _c(
                        "div",
                        [
                          _c("a-form-model-item", {
                            staticClass: "line-left",
                            attrs: {
                              label: "合同公司",
                              "label-col": _vm.labelColC,
                              "wrapper-col": _vm.wrapperColC,
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "transfer_content transfer_border" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  attrs: {
                                    title:
                                      _vm.transferDetailData
                                        .beforeContractCompanyName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transferDetailData
                                        .beforeContractCompanyName
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "middle_span" }, [
                                _vm._v("变更为"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div after_content_div",
                                  attrs: {
                                    title:
                                      _vm.transferDetailData
                                        .afterContractCompanyName,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.transferDetailData
                                        .afterContractCompanyName
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("a-form-model-item", { attrs: { label: "调动原因" } }),
                  _c("div", { staticClass: "transfer_content reasons" }, [
                    _vm._v(_vm._s(_vm.transferDetailData.reasons)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }